<template>
  <div class="xzl">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>智慧物联融合平台</h1>
          <p><a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />我们的优势
            <Icon class="arrow" type="ios-arrow-forward" />智慧物联融合平台
          </p>
        </div>
      </div>
      <div class="auto-container">
        <section class="rules-section">
          <div class="auto-container">
            <Row>
              <Col :xl="12" :lg="12">
              <div class="inner-column" style="padding: 0">
                <div class="image">
                  <a href="#"><img src="../../assets/resource/xzl.png" alt="" /></a>
                </div>
              </div>
              </Col>

              <Col :xl="12" :lg="12">
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title-icon">
                    <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
                  </div>
                  <h2>智慧物联融合平台</h2>
                </div>
                <div class="text">
                  <p>校智联是依托于具有智能感知能力的校园智慧融合平台，平台提供物联设备统一接入和开放式的平台架构，将各类物联感知设备接入统一平台进行管理，其中物联设备即包含了所有前端感知设备，
                    如视频监控、图像识别、告警防范、能耗设备、照明设备等。通过校智联将学校各类各型各厂家的智能硬件、智慧传感器的数据做汇聚及清洗整理，统一通过校智联平台进行设备管控、信息呈现和信息推送。</p>
                </div>
              </div>
              </Col>
            </Row>
          </div>
        </section>

        <Row style="padding-bottom:80px">
          <Col :xl="8" :lg="8" :md="12" :sm="24" v-for="(item, index) in feature" :key="index">
          <div class="feature-block-two">
            <div class="inner-box ">
              <div class="icon-box">
                <img :src="item.numberPic" alt="" />
              </div>
              <h3>{{ item.title }}</h3>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
          </Col>
        </Row>

        <div class="sec-title">
          <div class="title-icon">
            <span class="icon"><img src="../../assets/icons/separater.png" alt="" /></span>
          </div>
          <h2>图集</h2>
        </div>
        <Carousel v-model="value" loop autoplay dots="none" arrow="always" autoplay-speed="5000">
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhwl1.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhwl2.png" alt="" srcset="">
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="picBox">
              <img class="pic" src="../../assets/pic/zhwl3.png" alt="" srcset="">
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import xzl from "./xzl.js";
export default xzl;
</script>

<style lang="less" scoped>
@import "./xzl.less";
</style>