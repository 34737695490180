export default {
    data() {
        return {
            feature: [{
                title: '校智联和智慧校园无缝对接',
                numberPic: require("../../assets/icons/num1.png"),
                text: '平台具备完整的智慧校园体系，提供完整的教师管理、学生管理、学校组织架构管理、课表管理、场馆管理、人脸识别头像管理等校园基础管理，系统具备统一认证能力，提供各类第三方硬件厂商技术对接。'
            }, {
                title: '校智联智慧考勤打造精准上放学',
                numberPic: require("../../assets/icons/num2.png"),
                text: '校智联智慧考勤系统具备便捷的分布式人脸数据采集，可以管控门禁闸机、抓拍摄像机等考勤设备，同时将校园考勤系统的道闸通行、测温信息等数据实时推送至家长移动端，学校各级管理层面即时获取信息，异常信息也可以实时推送相关管理人员，接送大厅大屏幕学生实时进出播报，人员请假门禁自动授权。'
            }, {
                title: '支持智能化改造校内传统电气设备',
                numberPic: require("../../assets/icons/num3.png"),
                text: '校智联可以实现低成本实现校园传统电气设备智能化，通过改造传统灯光开关、空气开关、门锁、中控台、窗帘电机以及安装红外传感、教师环境传感器以及其他智慧传感器，实现教室和学校的智能化改造，通过智慧校园管理体系集中管控，以及情景模式等设置条件智能感知操作。'
            }, {
                title: '校智联构建安防联动体系',
                numberPic: require("../../assets/icons/num4.png"),
                text: '通过校智联平台，实现对进出闸机、防疫测温、车辆出入、视频安全围栏、视频监控、告警防范等集中数据处理，校智联实现校园多数据源、异构数据采集与接入，通过学校人脸中心库、物联感知、视频结构化分析等各类数据经过清洗、治理、智能分析等过程，实现校园智慧安防的安全联动体系。'
            }, {
                title: '具备多样的校园智慧物联数据看板',
                numberPic: require("../../assets/icons/num5.png"),
                text: '校园物联网感知系统通过炫酷大屏来直观展现校园整体设备状态，数据可视化的同时添加了动态交互效果，告警弹窗跟踪和巡检展示更进一步掌控系统状态，精细化的物联网终端管理及数据能耗分析，构建便捷管理的校园感知系统。'
            }],
        }
    },
    created() {

    },
}